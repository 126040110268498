import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { VariableState } from './types'

export const mutations = {
  setSelectedVariableIds(state: VariableState, variableId: number) {
    const variableIndex = state.selectedVariableIds.indexOf(variableId)
    if (variableIndex !== -1) {
      state.selectedVariableIds.splice(variableIndex, 1)
    } else {
      state.selectedVariableIds.push(variableId)
    }
  },
  setRequest,
}
