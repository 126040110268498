import { LocationQueryRaw } from 'vue-router'

import router from '@/router'

export default function useRedirection() {
  const redirect = (link: string, query: LocationQueryRaw) => {
    router.push({
      path: link,
      query,
    })
  }

  return {
    redirect,
  }
}
