import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { VariableState } from '@/store/modules/variable/types'

const baseUrl = '/api/variable'

const state = new VariableState()
state.requests = {
  exportVariables: new ApiRequest('exportVariables', 'POST', `${baseUrl}/export-json`),
}

export { state }
