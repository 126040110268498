import medicalUnitFilterStore from '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter'
import { createStore, Store, StoreOptions } from 'vuex'

import feedbackStore from '@/patient-feedback/store'
import { RootState } from '@/store/types'

import adminStore from './admin/store'
import criteriaStore from './criteria/store'
import homeStore from './home/store'
import loginStore from './login/store'
import patientListStore from './patient-list/store'
import searchStore from './search/store'
import settingsStore from './settings/store'
import criterionStore from './store/modules/criterion'
import jobStore from './store/modules/job'
import patientStore from './store/modules/patient'
import snomedStore from './store/modules/snomed'
import toastStore from './store/modules/toast'
import variableStore from './store/modules/variable'
import studyStore from './study/store'
import variablesStore from './variables/store'

const store = createStore({
  modules: {
    home: homeStore,
    patientList: patientListStore,
    settings: settingsStore,
    login: loginStore,
    admin: adminStore,
    search: searchStore,
    criteria: criteriaStore,
    variables: variablesStore,
    snomed: snomedStore,
    study: studyStore,
    job: jobStore,
    patient: patientStore,
    feedback: feedbackStore,
    toast: toastStore,
    medicalUnitFilter: medicalUnitFilterStore,
    criterion: criterionStore,
    variable: variableStore,
  },
  strict: process.env.NODE_ENV !== 'production',
} as StoreOptions<RootState>)

if (module.hot) {
  module.hot.accept([
    './home/store',
    './patient-list/store',
    './settings/store',
    './login/store',
    './admin/store',
    './search/store',
    './criteria/store',
    './variables/store',
    './study/store',
    './store/modules/job',
    './store/modules/patient',
    './store/modules/snomed',
    './patient-feedback/store',
    './store/modules/toast',
    './store/modules/criterion',
    './store/modules/variable',
    '@sancare/ui-frontend-commons/src/store/modules/filters/medical-unit-filter',
  ], () => {
    store.hotUpdate({
      modules: {
        home: homeStore,
        settings: settingsStore,
        login: loginStore,
        admin: adminStore,
        search: searchStore,
        criteria: criteriaStore,
        variables: variablesStore,
        study: studyStore,
        job: jobStore,
        patient: patientStore,
        snomed: snomedStore,
        feedback: feedbackStore,
        toast: toastStore,
        medicalUnitFilter: medicalUnitFilterStore,
        criterion: criterionStore,
        variable: variableStore,
      },
    })
  })
}

export function useStore(): Store<RootState> {
  return store
}

export default store
