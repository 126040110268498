import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from '@/store/modules/variable/state'
import { PayloadWithSuccessMut } from '@/store/types'

interface ExportVariablesPayload extends PayloadWithSuccessMut {
  ids: number[]
}

export const actions = {
  exportVariables({ commit }, payload: ExportVariablesPayload) {
    return Api.request(
      state.requests.exportVariables,
      {
        body: payload,
        successMut: (response) => response,
      },
      commit,
    )
  },
}
