import { SearchInterface, SearchTypeEnum } from '@/store/modules/search/types'

export interface GenericThumbnailButton {
  name: string
  label: string
  count: string
  linkPath: string
  linkQuery: Record<string, string>
}

export enum ThumbnailHeaderColor {
  UNIFORM = 'uniform',
  DISABLED = 'disabled',
  SELECTED = 'selected',
}

export interface HomeThumbnailProps {
  class?: string
  canClick?: boolean
  canSelect?: boolean
  isDisabled?: boolean
  emptyValue?: string | null
  footer?: string
  headerItem?: number | string | null
  label?: string
  name?: string
  isSelected?: boolean
}

export interface SearchListProps {
  searchType: SearchTypeEnum
  title: string
  editPath: string
  bgClass: string
  searches: SearchInterface[]
}
