import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from '@/store/modules/criterion/state'
import { PayloadWithSuccessMut } from '@/store/types'

interface ExportCriteriaPayload extends PayloadWithSuccessMut {
  ids: number[]
}

export const actions = {
  exportCriteria({ commit }, payload: ExportCriteriaPayload) {
    return Api.request(
      state.requests.exportCriteria,
      {
        body: payload,
        successMut: (response) => response,
      },
      commit,
    )
  },
}
