import { setRequest } from '@sancare/ui-frontend-commons/src/misc/api'

import { CriterionState } from './types'

export const mutations = {
  setSelectedCriteriaIds(state: CriterionState, criterionId: number) {
    const criterionIndex = state.selectedCriteriaIds.indexOf(criterionId)
    if (criterionIndex !== -1) {
      state.selectedCriteriaIds.splice(criterionIndex, 1)
    } else {
      state.selectedCriteriaIds.push(criterionId)
    }
  },
  setRequest,
}
